import clsx from 'clsx'
import { FC, useCallback, useEffect, useState } from 'react'
import { Waypoint } from 'react-waypoint'

import { PossibilitiesProps } from '@/components/PossibilitiesCards/Possibilities.interface'

import events from '@/utils/events'

import Type from './Type'

const PossibilitiesCards: FC<PossibilitiesProps> = ({
  type = 0,
  className = ''
}) => {
  const [animationStart, setAnimationStart] = useState(false)

  const toggleAnimateContent = useCallback((state: boolean) => {
    setAnimationStart(state)
  }, [])

  useEffect(() => {
    events.on('waypoint::Possibilities::enter', () =>
      toggleAnimateContent(true)
    )
    events.on('waypoint::Possibilities::leave', () =>
      toggleAnimateContent(false)
    )

    return () => {
      events.off('waypoint::Possibilities::enter', () =>
        toggleAnimateContent(true)
      )
      events.off('waypoint::Possibilities::leave', () =>
        toggleAnimateContent(false)
      )
    }
  }, [])

  return (
    <div className={clsx({ 'animation-ready': animationStart }, className)}>
      <Waypoint
        onEnter={() => events.emit('waypoint::Possibilities::enter')}
        onLeave={() => events.emit('waypoint::Possibilities::leave')}
      >
        <div>
          <Type
            key={Math.random()}
            id={type}
            toggleAnimateContent={toggleAnimateContent}
          />
        </div>
      </Waypoint>
    </div>
  )
}
export default PossibilitiesCards
