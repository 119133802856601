import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useState } from 'react'

import Heading from '@/components/Heading'
import PossibilitiesCards from '@/components/PossibilitiesCards'
import Tabs from '@/components/Tabs'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Section.module.scss'

interface TabsProps {
  title: string
  id: number
}

export const Features: FC<DefaultComponentProps> = ({ className = '' }) => {
  const { t } = useTranslation()
  const TABS = t('features:tabs', { returnObjects: true }) as TabsProps[]

  const [activeId, setActiveId] = useState(TABS[0].id)

  const handleClick = (id: number) => {
    setActiveId(id)
  }
  return (
    <section id="opportunities" className={clsx(styles['section'], className)}>
      <div className={styles['section__content']}>
        <Heading className={styles['section__heading']} level={2}>
          {t('features:heading')}
        </Heading>
        <Tabs
          className={styles['section__tabs']}
          items={TABS.map(({ title, id }) => ({
            id,
            active: id === activeId,
            name: title
          }))}
          onClick={handleClick}
        />
        <PossibilitiesCards
          className={styles['section__possibilities']}
          type={activeId}
        />
      </div>
    </section>
  )
}
